import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Link from './Link';

export default props => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper sx={{p: 1}} variant={
      props.variant || "outlined"
    }>
      <Stack direction="row" alignItems="center" justifyContent={
        mobile ? "start" : "center"
      }>
        <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
          style={{
            height: 40,
            position: "relative",
            top: 3
          }} />
        <Typography variant="body2" fontWeight={600}>
          Gravy was built by the team behind <Link color="inherit" color="secondary" fontWeight={600} component={RouterLink} to="/about">Plaid</Link>
        </Typography>
      </Stack>
    </Paper>
  )
}
