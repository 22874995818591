import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useOutletContext } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import request from '../../utils/request';
import PaymentMethods from "../paymentMethods/List";
import Alert from '../../components/Alert';

export default function OrdersNew(props) {
  const {
    amount,
    merchant,
    proposition,
    createAlert,
    open,
    onClose,
    history
  } = props;

  const [saving, setSaving] = React.useState(false);
  const [paymentMethodId, setPaymentMethodId] = React.useState(null)

  const probability = _.get(
    proposition,
    ["propositionProbabilities", 0, "probability"], 0
  );

  const prize = probability ? _.floor(
    _.min([
      0.03 / probability * 100,
      100
      ]
    )
  ) : 0;

  const createOrder = async () => {
    try {
      setSaving(true);

      const { data } = await request.post(`orders`, {
        amount_cents: amount * 100,
        payment_method_id: paymentMethodId,
        proposition_id: proposition.id,
        merchant_id: merchant.id,
      })

      if (data.errors) {
        alert(data.errors.message)
      } else {
        createAlert({title: "Order success!"})
        window.location.href = "/app/activities"
      }
    } catch(err) {
      alert("Sorry, there was an error please try again. Contact help@itsgravy.co if it continues.")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box mt={2}>
          <Stack spacing={2}>
            <img src={merchant?.logo_url} style={{
              width: 80,
              borderRadius: 5
            }} />

            <Box>
              <Typography variant="h3" fontWeight={600} sx={{
                lineHeight: 1.3,
              }}>
                Buy ${amount} Gift Card
              </Typography>
              <Typography variant="h5" fontWeight={500} lineHeight={1.3}>
                Add you payment information.
              </Typography>
            </Box>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Alert color="primary">
            <Typography variant="body2" fontWeight={600}>
              {prize}% refund if {proposition?.winDescription}
            </Typography>
          </Alert>
        </Box>
        <PaymentMethods
          paymentMethodId={paymentMethodId}
          setPaymentMethodId={setPaymentMethodId} />
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          size="large"
          disabled={amount == null || !paymentMethodId}
          loading={saving}
          variant="contained"
          onClick={createOrder}>
          Purchase
        </LoadingButton>
        <Button onClick={onClose}>
          Back
        </Button>

      </DialogActions>

    </Dialog>
  );
}
