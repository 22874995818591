import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Iconify from '../../components/iconify';
import Alert from '../../components/Alert';

import withRouter from '../../utils/withRouter';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';

import ClickablePaper from '../../components/ClickablePaper';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import Card from "./Card";

function CardsList(props) {
  const { cards, activeCard, setActiveCard, history } = props;

  const carouselRef = React.useRef(null);

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'scroll',      // Enable horizontal scrolling
        scrollSnapType: 'x mandatory', // For smooth snap scrolling
        padding: 2,
      }}
    >
      {cards.map((card) => (
        <Box
          id={`card-${card.id}`}
          key={card.id}
          ref={carouselRef}
          sx={{
            width: 300,
            marginRight: 2,          // Spacing between Paper components
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            scrollSnapAlign: 'start', // Make each Paper snap into place
          }}
        >
          <ClickablePaper
            onClick={() => {
              history.push(`./${card.id}`)
            }}
            variant="outlined"
            selected={card.id == activeCard.id}
            sx={{
              p: 2,
              width: 300,
              height: 120
            }}>
            <Box height={60}>
              {
                card.source == "plaid" ?
                  <Box sx={{position: "relative", top: 10}}>
                    <Typography variant="body2" fontWeight={600}>{card.name}</Typography>
                  </Box> :
                  <Iconify width={40} icon="logos:visa" />

              }
            </Box>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">{
                `******${card.last_4}`
              }</Typography>

              <Button variant="outlined" size="small">
                Details
              </Button>
            </Stack>

          </ClickablePaper>
        </Box>
      ))}
    </Box>
  )
}

const CardsMain = props => {
  const {
    user,
    cards,
    fetchCards,
    savePlaidUser,
    merchantOffers
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [activeCard, setActiveCard] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const sortedCards = _.sortBy(cards, [
    (card) => card.id !== activeCard.id,
    (card) => card.source !== "plaid",
    (card) => new Date(card.created_at)
  ])

  React.useEffect(() => {
    if (cards.length > 0) setActiveCard(
      _.get(sortedCards, "0", {})
    )
  }, [cards.length])

  const connectToPlaid = async token => {
    try {
      setSaving(true)
      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.href = "/app/account/cards/"
      }
    } catch(err) {
      console.log(err)
      alert("Unknown error please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box pt={2} pb={10}>
      <Container maxWidth="sm">
        {
          loading ?
            <Loader /> :
            <Box>
              <Box mb={2}>
                <Typography variant="h1" fontWeight={600} lineHeight={1.1}>
                  Shop with your card
                </Typography>
                <Typography variant="h4" fontWeight={400}>
                  Earn picks for each purchase.
                </Typography>
              </Box>

              <Box mt={2} mb={2}>
                <Button startIcon={<AddIcon />} variant="contained" component={RouterLink} to="./new">
                  Add card (earn pick)
                </Button>
              </Box>

              <CardsList
                {...props}
                cards={sortedCards}
                activeCard={activeCard}
                setActiveCard={setActiveCard} />
          </Box>
        }
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "cards", "merchantOffers"]);
export default connect(select, {
  ...cardActions,
  ...userActions
})(withRouter(CardsMain));
