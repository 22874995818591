import _ from "lodash";
import * as React from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import Alert from '../../components/Alert';
import Link from '../../components/Link';

function Timer() {
  const [timeRemaining, setTimeRemaining] = React.useState(60 * 60); // 60 minutes in seconds

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        clearInterval(intervalId);
        // Optionally, do something when the timer reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <Typography variant="h1" textAlign="center">
      {minutes}:{seconds.toString().padStart(2, '0')}
    </Typography>
  );
}

export default function InstructionsFreebie(props) {
  return (
    <Box>
      <Box>
        <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
          First Pick is on us
        </Typography>
        <Typography variant="body1" lineHeight={1.3}>
          You get one Pick just for being awesome.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography textAlign="center" variant="body4" fontWeight={600} textTransform="uppercase">
          Expires In
        </Typography>
        <Timer />
      </Box>
    </Box>
  );
}
