import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { ShoppingBasket, Activity, Camera, Soup, Zap, ChevronRight } from 'lucide-react';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as transactionActions from '../../actions/transactions';

import PlaidLogo from '../../icons/PlaidLogo';

import Earn from '../earn/List';

const DashboardHome = props => {
  const {
    user,
    feedItems,
    picks,
    transactions,
    fetchTransactions,
    params,
    history,
    location,
    teams
  } = props;

  React.useEffect(() => {
    // Scroll to the top of the window on first render
    window.scrollTo(0, 0);

    if (transactions.length == 0) fetchTransactions()
  }, []);

  const amount_cents = _.get(transactions, ["0", "balance_cents"], 0);

  return (
    <Box pb={12} sx={{background: "#f5f5f5"}}>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{px: 2, py: 4}}>
              <Stack spacing={2} alignItems="start">
                <Box>
                  <Typography variant="body2">
                    Balance
                  </Typography>
                  <Typography variant="h1" fontWeight={600}>
                    {
                      currencyFormatter(amount_cents / 100, 2)
                    }
                  </Typography>
                </Box>
                <Button
                  disabled={amount_cents == 0}
                  variant='contained'
                  component={RouterLink}
                  to={"./payout"}>
                  Withdraw
                </Button>
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <ClickablePaper
              variant="outlined"
              sx={{px: 2, py: 4}}
              onClick={() => {
                history.push(`./shop`)
              }}>
              <Stack spacing={2}>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="bodye" fontWeight={600}>
                      Shop
                    </Typography>

                    <ChevronRight />
                  </Stack>
                  <Box mt={1}>
                    <Avatar sx={{bgcolor: "#4a5dff"}}>
                      <ShoppingBasket />
                    </Avatar>
                  </Box>
                </Box>
                <Typography variant="body3" color="text.secondary">
                  Refunds when cool things happen in the world.
                </Typography>
              </Stack>
            </ClickablePaper>
          </Grid>
          <Grid item xs={6}>
            <ClickablePaper
              variant="outlined"
              sx={{px: 2, py: 4}}
              onClick={() => {
                window.open("https://app.itsgravy.co/")
              }}>
              <Stack spacing={2}>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="bodye" fontWeight={600}>
                      Receipts
                    </Typography>
                    <ChevronRight />
                  </Stack>

                  <Box mt={1}>
                    <Avatar sx={{bgcolor: "#ff634a"}}>
                      <Camera />
                    </Avatar>
                  </Box>
                </Box>

                <Typography variant="body3" color="text.secondary">
                  Upload receipts to receive huge refunds.
                </Typography>
              </Stack>
            </ClickablePaper>
          </Grid>
          <Grid item xs={6}>
            <ClickablePaper
              variant="outlined"
              sx={{px: 2, py: 4}}
              onClick={() => history.push("./feed")}>
              <Stack spacing={2}>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="bodye" fontWeight={600}>
                      Picks
                    </Typography>

                    <ChevronRight />
                  </Stack>

                  <Box mt={1}>
                    <Avatar sx={{bgcolor: "#ff4ae0"}}>
                      <Soup />
                    </Avatar>
                  </Box>
                </Box>

                <Typography variant="body3" color="text.secondary">
                  Win when you guess sports, news, and culture.
                </Typography>
              </Stack>
            </ClickablePaper>
          </Grid>
          <Grid item xs={6}>
            <ClickablePaper
              variant="outlined"
              sx={{px: 2, py: 4}}
              onClick={() => history.push(`./points`)}>
              <Stack spacing={2}>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="bodye" fontWeight={600}>
                      Points
                    </Typography>

                    <ChevronRight />
                  </Stack>

                  <Box mt={1}>
                    <Avatar sx={{bgcolor: "#ffa64a"}}>
                      <Zap />
                    </Avatar>
                  </Box>
                </Box>

                <Typography variant="body3" color="text.secondary">
                  Turn points into free gift cards to major brands.
                </Typography>
              </Stack>
            </ClickablePaper>
          </Grid>

        </Grid>
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "feedItems", "teams", "picks", "transactions"]);
export default connect(select, {
  ...transactionActions,
  ...userActions,
})(withRouter(DashboardHome));
