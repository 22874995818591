import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import withRouter from '../../utils/withRouter';
import * as payoutActions from '../../actions/payouts';
import currencyFormatter from '../../utils/currencyFormatter';

function PayoutsNew(props) {
  const { createPayout, transactions, history } = props;

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("../");

  const amount_cents = _.get(transactions, ["0", "balance_cents"], 0);

  const save = async () => {
    try {
      setSaving(true);

      const results = await createPayout({
        amount_cents
      })

      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.href = "/app/account/savings"
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false);
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            Withdraw to Visa Card
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            Your { currencyFormatter(amount_cents / 100, 2)} will be sent to you as a Visa Gravy card.
          </Typography>
        </Box>

      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={saving}
          onClick={save}
          variant="contained"
          color="primary">
          Complete
        </LoadingButton>
      </DialogActions>
    </Dialog>

  );
}

const select = $$state => _.pick($$state, "user", "transactions");
export default connect(select, {
  ...payoutActions
})(withRouter(PayoutsNew));
