import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

import withRouter from '../../utils/withRouter';
import request from '../../utils/request';
import * as feedItemActions from '../../actions/feedItems';

import Loader from '../../components/Loader';
import PlaidBuilt from '../../components/PlaidBuilt';

import TopNavBar from '../marketing/TopNavBar';

function RewardsMain(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    params
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [reward, setReward] = React.useState({})

  React.useEffect(() => {
    fetchReward().finally(() => setLoading(false))
  }, [])

  React.useEffect(() => {
    if (!!user.id) fetchFeedItems(user.id)
  }, [user.id]);

  const fetchReward = async () => {
    const { data } = await request.get(`rewards/${params.rewardId}`)

    if (!data.errors) {
      setReward({
        id: data.id,
        ...data.attributes
      });
    }
  }

  return (
    <Box sx={{
      background: "#fff",
      minHeight: "calc(var(--vh, 1vh) * 100)",
      position: "relative"
    }} pb={12}>
      <Box>
        <Container maxWidth="sm">
          <Box mb={2}>
            <IconButton sx={{position: "relative", left: -10}} component={RouterLink} to="/">
              <img
                src="https://res.cloudinary.com/dxxog3y9j/image/upload/v1726686197/gravy_1_we9oi7.png"
                style={{
                  height: 40
                }}/>

            </IconButton>
          </Box>

          {
            loading ?
              <Loader /> :
              <Outlet context={{
                ...props,
                reward,
              }} />
          }
        </Container>
      </Box>

      <Box sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0
      }}>
        <PlaidBuilt />
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "feedItems"]);
export default connect(select, {
  ...feedItemActions
})(withRouter(RewardsMain));
