import _ from "lodash";
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Gift } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as dates from '../../utils/dates';

import Confirmation from './Confirmation';
import Explanation from './Explanation';
import AuthButton from '../user/AuthButton';

export default function RewardsDetails(props) {
  const {
    user,
    reward,
    feedItems,
    fetchFeedItems,
    params,
    history
  } = useOutletContext();

  const [open, setOpen] = React.useState(false)
  const [selectedProposition, setSelectedProposition] = React.useState(null);
  const toggleOpen = () => setOpen(!open)

  const { merchant, amount_cents, cost_cents, sender_name, propositions } = reward;

  const proposition = propositions[0];

  const { event, eligibility_ends_at, win_description } = proposition;

  function convertQuestionToStatement(sentence) {
    // Check if the sentence starts with "Will" and ends with a question mark
    if (sentence.startsWith("Will") && sentence.endsWith("?")) {
      // Remove "Will" from the start and the question mark from the end
      let statement = sentence.slice(4, -1).trim();

      // Split the statement into words to detect the verb
      let words = statement.split(' ');

      // Find the first verb and convert it to the present tense
      const verbMapping = {
        'win': 'wins',
        'be': 'is',
        'have': 'has',
        'do': 'does',
        'go': 'goes',
        'make': 'makes',
        'take': 'takes',
        'give': 'gives',
        'get': 'gets',
        'say': 'says'
        // Add more verb mappings as needed
      };

      // Loop through the words to find the verb
      for (let i = 0; i < words.length; i++) {
        let word = words[i].toLowerCase();
        if (verbMapping[word]) {
          // Replace the verb with its present tense form
          words[i] = verbMapping[word];
          break;
        }
      }

      // Reassemble the sentence and append a period
      return words.join(' ') + '.';
    }

    // If the sentence doesn't start with "Will", replace the question mark with a period if it exists
    return sentence.endsWith('?') ? sentence.slice(0, -1) + '.' : sentence;
  }

  return (
    <Box>
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="h2" fontWeight={400} textAlign="center">
          {
            sender_name
          } sent you a Gift
        </Typography>

        <Typography variant="h1" fontWeight={600} textAlign="center" mt={0.5}>
          {
            currencyFormatter(amount_cents / 100, 0)
          } {
            merchant?.name
          } Gift Card IF...
        </Typography>
      </Stack>

      {
        false &&
          <Box mt={2}>
            <Alert color="secondary">
              <Link fontWeight={600} variant="body2" component={RouterLink} to="" color="secondary" textDecoration="italic" onClick={() => {
                toggleOpen()
              }}>How does this work exactly?</Link>
            </Alert>
          </Box>

      }

      <Box mt={3}>
        {
          propositions.length == 0 ?
            <Stack alignItems="center">
              <Button variant="contained" size="large" color="secondary">
                Accept gift
              </Button>
            </Stack> :
            <Box>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: 2,
                }}>
                  <Box>
                    <Box mb={3}>
                      <img src={event.image_url} style={{
                        height: 50,
                        width: 50,
                        borderRadius: 8
                      }} />

                      <Box mt={1}>
                        <Typography variant="h5" fontWeight={600} sx={{
                          lineHeight: 1.3,
                        }}>
                          {
                            event.title
                          }
                        </Typography>

                        <Typography variant={"h2"} fontWeight={500} lineHeight={1.3}>
                          {
                            convertQuestionToStatement(win_description)
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Stack spacing={1}>
                      <LoadingButton fullWidth variant="contained" size="large" color="secondary" onClick={() => {
                        setSelectedProposition(proposition)
                      }}>
                        Pick
                      </LoadingButton>
                      <AuthButton onAuthentication={() => {
                        history.push(`/rewards/${reward.token}/feed`)
                      }}>

                        <Button fullWidth variant="contained" size="large" color="primary">
                          More choices
                        </Button>
                      </AuthButton>
                    </Stack>
                  </Box>
              </Paper>
            </Box>
        }
      </Box>


      <Outlet context={{
        feedItems,
        user,
        reward,
        history
      }} />

      <Confirmation
        reward={reward}
        proposition={selectedProposition}
        open={!!selectedProposition}
        onClose={() => setSelectedProposition(null)}
        history={history} />

    </Box>
  )
}
