import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import categoryBackgrounds from '../../utils/categoryBackgrounds';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as cardActions from '../../actions/cards';
import withRouter from '../../utils/withRouter';

import {usePostHog} from "posthog-js/react";

function MerchantOffersList(props) {
  const {
    merchantOffers,
    fetchMerchantOffers,
    cards,
    fetchCards,
    params,
    history
  } = props;

  const [loading, setLoading] = React.useState(true)

  const categories = [
    "coffee",
    "restaurant",
    "food_delivery",
    "bar",
    "ride_share",
    "groceries",
    "dessert",
    "clothing",
    "ticketing"
  ];

  const onClose = () => history.replace("../");

  const prettyCategoryName = category => {
    const lastLetter = category[category.length - 1];

    switch(lastLetter) {
      case "s":
      case "a":
      case "e":
      case "i":
      case "o":
      case "u":
      case "g":
        return category;
      case "y":
        return `${_.trimEnd(category, lastLetter)}ies`
      default:
        return `${category}s`
    }
  }

  React.useEffect(() => {
    Promise.all([
      merchantOffers.length == 0 ? fetchMerchantOffers() : Promise.resolve(),
      cards.length == 0 ? fetchCards() : Promise.resolve(),
    ]).finally(() => setLoading(false))
  }, [])

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const showMerchants = plaidCard != null;

  return (
    <Box>
      <Box>
        <Grid container spacing={1}>
          {
            _.map(merchantOffers, (mo, i) => {
              const logo = mo.merchant.logo_url ||
                _.get(mo.merchant, ["logo", "secure_url"]);

              return (
                <Grid item xs={6} md={4} key={i}>
                  <ClickablePaper variant="outlined" p={2} sx={{
                    height: {
                      xs: 200,
                      md: 200
                    },
                    display: "flex",
                  }} onClick={() => {
                    history.push(`./${mo.id}`)
                  }}>
                    <Stack alignItems="center" justifyContent="center" sx={{flexGrow: 1}} spacing={2}>
                      <img src={logo} style={{
                        height: 80,
                        borderRadius: 5
                      }} />

                      <Button variant="outlined" size="small">
                        Shop { mo.merchant.name}
                      </Button>
                    </Stack>
                  </ClickablePaper>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>

      <Outlet />
    </Box>
  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "cards"]);
export default connect(select, {
  ...merchantOffersActions,
  ...cardActions,
})(withRouter(MerchantOffersList));
