import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as alertActions from '../../actions/alerts';

function ReferralsNew(props) {
  const { user, referrals, createAlert, history } = props;

  const url = `https://${window.location.host}?ref=${user.referral_code}`;

  const onClose = () => history.push("../")

  return (
    <FullScreenDialog
      open={true}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="h2" fontWeight={600}>
            Share Gravy with friends
          </Typography>

          <Typography variant="body1" fontWeight={500} color="text.secondary">
            One Pick for each friend who activates Gravy.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <img src="https://p34.f1.n0.cdn.zight.com/items/RBuR4bAm/6f70c2c8-1437-4696-9f57-5c56367e167e.png?source=viewer&v=975c50d1540f1027a226bebc62f1b160"
            style={{
              width: "100%"
            }} />
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <CopyToClipboard
          text={url}
          onCopy={() => {
            createAlert({title: "URL copied to clipboard"})
            onClose()
          }}>
          <Button size="large" variant="contained" color="primary">
            Copy Referral Link
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </FullScreenDialog>

  );
}

const select = $$state => _.pick($$state, "user", "referrals");
export default connect(select, {
  ...alertActions
})(withRouter(ReferralsNew));
