import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Soup, Zap, ChartColumnIncreasing } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as feedItemsActions from '../../actions/feedItems';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as payoutActions from '../../actions/payouts';
import * as alertActions from '../../actions/alerts';
import { fShortenNumber } from '../../utils/format-number';

import OrdersNew from '../orders/New';

function Cash(props) {
  const { user, feedItems, fetchFeedItems, merchant } = props;

  const [activeProposition, setActiveProposition] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [tab, setTab] = React.useState(0);

  const getProbability = p => _.get(
    p,
    ["propositionProbabilities", 0, "probability"], 0
  );

  const categories = ["all", "sports", "news", "culture", "crypto"];

  React.useEffect(() => {
    if (feedItems.length == 0) fetchFeedItems(user.id)
  }, [])

  const relevantFeedItems = _.filter(feedItems, feedItem => {
    const now = moment()
    const targetDateTime = moment.utc(feedItem.startAt, "YYYY-MM-DD HH:mm:ss A").tz('UTC');
    const diff = moment.duration(targetDateTime.diff(now)).asDays();

    if (
      feedItem.feedableType == "SportsEvent" &&
      diff > 8
    ) return false

    const desiredCategory = categories[tab];

    if (desiredCategory === "all") {
      return true;
    } else if (desiredCategory === "sports") {
      return feedItem.feedableType == "SportsEvent" || feedItem.feedableType == "Player"
    } else {
      return feedItem.feedable.category === desiredCategory;
    }
  });

  return (
    <Box>
      <Box mb={2}>
        <Alert color="primary">
          <Typography variant="body2" fontWeight={600}>
            Pick one below and save if it happens.
          </Typography>
        </Alert>
      </Box>

      <Box mt={2} mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={tab} onChange={(e, tab) => {
          setTab(tab)
        }}>
          {
            _.map(categories, t => {
              return (
                <Tab key={t} sx={{
                  textTransform: "inherit",
                  fontWeight: 600,
                  fontSize: 14
                }} label={
                  <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                    {
                      t
                    }
                  </Typography>
                  }  />
              )
            })
          }
        </Tabs>
      </Box>

      {
        relevantFeedItems.length == 0 ?
          <Loader / > :
          <Stack spacing={2}>
            {

              _.chain(relevantFeedItems)
              .map((fi, i) => {
                const proposition = _.chain(fi.propositions)
                  .filter(p => getProbability(p) > 0)
                  .sortBy(p => getProbability(p))
                  .first()
                  .value()

                return [proposition, fi]
              })
              .reject(pair => pair[0] == null)
              .sortBy(pair => getProbability(pair[0]))
              .map((pair, i) => {
                const proposition = pair[0];
                const fi = pair[1];

                const { story, title, startAt, imageUrl, feedableType } = fi;

                const probability = getProbability(proposition);

                return (
                  <ClickablePaper variant="outlined" key={i} onClick={() => {
                    setActiveProposition(proposition)
                  }}>
                      <Box>
                        <Stack spacing={2} sx={{mb: 2}}>
                          <img
                            src={imageUrl}
                            style={{
                              borderRadius: 8,
                              height: 60,
                              width: 75
                            }} />

                          <Box>
                            <Typography variant="h5" fontWeight={600}>
                              {
                                title
                              }
                            </Typography>

                            <Typography variant="h2" fontWeight={400}>
                              <b>{
                                _.floor(_.min(
                                  [
                                    0.03 / probability * 100,
                                    100
                                  ]
                                ))
                              }% refund</b> if {proposition.winDescription}
                            </Typography>
                          </Box>
                        </Stack>

                        <LoadingButton size="large" fullWidth loading={false} variant="contained">
                          Select
                        </LoadingButton>
                      </Box>
                  </ClickablePaper>
                )
              }).value()
            }
          </Stack>
      }


      <OrdersNew
        {...props}
        open={!!activeProposition}
        onClose={() => setActiveProposition(null)}
        merchant={merchant}
        proposition={activeProposition} />
    </Box>
  )
}

function Amount(props) {
  const { user, amount, setAmount, merchant } = props;

  return (
    <Box>
      <Box mt={2} mb={1}>
        <Alert color="primary">
          <Typography variant="body2" fontWeight={600}>
            Choose your gift card value
          </Typography>
        </Alert>
      </Box>

      <Box mb={2}>
        <ButtonGroup fullWidth size="large">
          <Button fullWidth variant={amount == 15 ? "contained" : "outlined"} onClick={() => setAmount(15)}>
            $15
          </Button>
          <Button fullWidth variant={amount == 25 ? "contained" : "outlined"} onClick={() => setAmount(25)}>
            $20
          </Button>
          <Button fullWidth variant={amount == 30 ? "contained" : "outlined"} onClick={() => setAmount(30)}>
            $30
          </Button>

          {
            false &&
              <Button fullWidth variant={amount == 50 ? "contained" : "outlined"} onClick={() => setAmount(50)}>
                $50
              </Button>
          }
        </ButtonGroup>
      </Box>
    </Box>
  )
}

function MerchantDetails(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    merchantOffers,
    createAlert,
    createPayout,
    params,
    history
  } = props;

  const onClose = () => {
    setStep(0)
    history.replace("../");
  }

  const [step, setStep] = React.useState(0);
  const [amount, setAmount] = React.useState(25);

  const merchantOffer = _.find(merchantOffers, mo => mo.id == params.merchantOfferId);
  const merchant = merchantOffer?.merchant;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="h2" fontWeight={600}>
            Shop {
              merchant?.name
            } Gift Cards
          </Typography>
          <Typography variant="h4" fontWeight={400} lineHeight={1.2}>
            Up to 100% cash back when cool things happen in the world.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          step == 0 &&
            <Amount
              {...props}
              merchant={merchant}
              amount={amount}
              setAmount={setAmount} />
        }

        {
          step == 1 &&
            <Cash
              {...props}
              amount={amount}
              merchant={merchant} />
        }

      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        {
          step == 0 &&
            <Button variant="contained" onClick={() => {
              setStep(1)
            }}>
              Next
            </Button>
        }

        {
          step == 1 &&
            <Button onClick={() => {
              setStep(0)
            }}>
              Back
            </Button>
        }
      </DialogActions>

    </Dialog>

  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "feedItems", "points"]);
export default connect(select, {
  ...feedItemsActions,
  ...alertActions,
  ...merchantOffersActions,
  ...payoutActions,
})(withRouter(MerchantDetails));
