import _ from "lodash";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Soup, CircleDollarSign, Activity, Trophy, CreditCard, ShoppingBasket, Home } from 'lucide-react';

const getScreen = pathname => {
  switch (true) {
    case /earn/.test(pathname):
      return "earn";
    case /feed/.test(pathname):
      return "feed";
    case /activities/.test(pathname):
      return "activities";
    case /leaderboard/.test(pathname):
      return "leaderboard";
    case /shop/.test(pathname):
      return "shop";
    case /account/.test(pathname):
      return "account";
    default:
      return "home";
  }
}

function BottomNavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const screen = getScreen(pathname);

  return (
    <AppBar position="fixed" color="primary" sx={{
      top: 'auto',
      bottom: 0,
      background: "#fff",
      color: "text.primary",
      boxShadow: "none",
      borderTop: "1px solid #cccccc",
    }}>
      <Container maxWidth="sm">
        <Box py={1}>
          <Stack direction="row" spacing={6} sx={{
            justifyContent: "center",
            width: "100%"
          }}>

            <Box>
              <IconButton component={RouterLink} to="/app">
                <Home color={screen == "home" ? "#343a40" : "rgba(0, 0, 0, 0.25)"} />
              </IconButton>
            </Box>

            <Box>
              <IconButton component={RouterLink} to="/app/shop">
                <ShoppingBasket color={screen == "shop" ? "#343a40" : "rgba(0, 0, 0, 0.25)"} />
              </IconButton>
            </Box>

            <Box>
              <IconButton component={RouterLink} to="/app/activities">
                <Activity color={screen == "activities" ? "#343a40" : "rgba(0, 0, 0, 0.25)"} />
              </IconButton>
            </Box>

            {
              false &&
                <Box>
                  <IconButton component={RouterLink} to="/app/leaderboard">
                    <Trophy color={screen == "leaderboard" ? "#343a40" : "rgba(0, 0, 0, 0.25)"} />
                  </IconButton>
                </Box>
            }

          </Stack>
        </Box>
      </Container>
    </AppBar>
  );
};

export default BottomNavBar;
