import _ from "lodash";
import moment from "moment";
import qs from 'qs';
import Confetti from 'react-confetti';
import * as React from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Outlet,
  useOutletContext,
  useLocation,
  useNavigate,
  Link as RouterLink,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpIcon from '@mui/icons-material/Help';

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import withRouter from "../../utils/withRouter";
import * as time from "../../utils/time";
import * as dates from "../../utils/dates";
import request from "../../utils/request";
import * as offersActions from "../../actions/offers";
import currencyFormatter from "../../utils/currencyFormatter";
import FullScreenDialog from "../../components/FullScreenDialog";

import Instructions from "./Instructions";
import Faqs from "./Faqs";
import AuthButton from "../user/AuthButton";

import categoryBackgrounds from '../../utils/categoryBackgrounds';

import Lost from './Lost';
import Won from './Won';

function OfferDetails(props) {
  const {
    offers,
    user,
    deleteOffer,
    merchantOffers,
    picks,
    params,
    history,
    location,
  } = props;

  const offerId = params.offerId;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const onClose = () => history.replace("../");

  const [saving, setSaving] = React.useState(false);
  const [offer, setOffer] = React.useState({});
  const [isExploding, setIsExploding] = React.useState(search.n == 1);

  const fetchOfferDetails = async id => {
    const { data } = await request.get(`offers/${id}`)
    if (!data.errors) {
      setOffer({
        ...data
      })
    }
  }

  React.useEffect(() => {
    fetchOfferDetails(params.offerId)
  }, [params.offerId])

  const { proposition, prize, status, feed_item, requirement_title } = offer;

  const prizeStatus = _.get(prize, ["status"], "pending");

  const categories = ["bar", "food_delivery", "restaurant", "groceries"];

  const destroyOffer = async () => {
    try {
      setSaving(true);
      const results = await deleteOffer(offer.id);
      if (results.errors) {
        alert(results.errors.message);
      } else {

        history.push(`../`)
      }
    } catch (err) {
      alert(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      {
        isExploding &&
          <Confetti
          width={window.innerWidth}
          height={330}
        />
      }

      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="body1" fontWeight={500} lineHeight={1.3} mb={0.5}>
            {feed_item?.title}
          </Typography>

          {
            !!proposition?.win_description &&
              <Typography
                variant="h3"
                fontWeight={500}
                lineHeight={1.3}>
                <b>{prize?.description}</b> if {proposition?.win_description}.
              </Typography>

          }

        </Box>
      </DialogTitle>
      <DialogContent sx={{px: 2}}>
        {
          _.isEmpty(offer) ?
            <Loader /> :
            <Box>

              <Box mb={4}>

                {
                  status == "verified" &&
                    <Alert color="primary">
                      <Typography variant="body2" fontWeight={600}>
                        You'll get notified if you win or lose!
                      </Typography>
                    </Alert>
                }

                {
                  status == "won" &&
                    <Won prizeStatus={prizeStatus} />
                }


                {
                  status == "lost" &&
                    <Lost />
                }
              </Box>
            </Box>
        }

        <Outlet />
      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        {
          picks.length > 0 ?
            <Button
              component={RouterLink}
              to="/app"
              variant="contained"
              size="large">
              New Pick
            </Button> :
            <Button
              component={RouterLink}
              to="/app/earn"
              variant="contained"
              size="large">
              Earn Picks
            </Button>

        }
      </DialogActions>
    </Dialog>
  );
}

const select = ($$state) =>
  _.pick($$state, [
    "user",
    "offers",
    "merchantOffers",
    "picks"
  ]);
export default connect(select, {
  ...offersActions,
})(withRouter(OfferDetails));
