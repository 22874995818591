import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';

import PlaidLogo from '../../icons/PlaidLogo';

import FeedItemCard from '../feedItems/Card';
import Card from '../cards/Card';

import Wallet from '../cards/Wallet';

export default function InstructionsShop(props) {
  const {
    user,
    merchantOffers,
    card,
    next,
  } = props;

  return (
    <Box>
      <Box>
        <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
          Apply your wins to savings
        </Typography>
        <Typography variant="body1" lineHeight={1.3}>
          Up to 100% off future purchases at major merchants like...
        </Typography>
      </Box>

      <Box mt={3}>

        {
          card?.source == "gravy" ?
            <Box /> :
            <Grid container spacing={1}>
              {
                  _.chain(merchantOffers)
                    .take(6)
                    .map((mo, i) => {
                      const logo = _.get(mo, ["merchant", "logo", "secure_url"]);
                      return (
                        <Grid item xs={6} md={6} key={i}>
                          <Paper variant="outlined" p={2} sx={{
                            height: {
                              xs: 130,
                              md: 200
                            },
                            display: "flex",
                          }}>
                            <Stack alignItems="center" justifyContent="center" sx={{flexGrow: 1}}>
                              <img src={logo} style={{
                                height: 80,
                                borderRadius: 5
                              }} />
                            </Stack>
                          </Paper>
                        </Grid>
                      )
                    })
                    .value()
              }
            </Grid>
        }
      </Box>
    </Box>
  );
}
