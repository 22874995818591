import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Soup, CircleUser, PiggyBank, Zap } from 'lucide-react';

import Logo from '../../components/Logo';
import Link from '../../components/Link';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import { fShortenNumber } from '../../utils/format-number';
import * as pointsActions from '../../actions/points';
import * as picksActions from '../../actions/picks';

import AuthButton from '../user/AuthButton';

function TopNavBar(props) {
  const { user, points, fetchPoints, picks, fetchPicks, history, location } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [scrolled, setScrolled] = React.useState(false);
  const [savings, setSavings] = React.useState(0);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  React.useEffect(() => {
    let pollingPicksId;

    fetchData().finally(() => {
      pollingPicksId = pollPicks();
    })
    // Cleanup function to clear the interval on component unmount
    return () => {
      clearInterval(pollingPicksId)
    };
  }, []);

  const pollPicks = () => {
    const pollingId = setInterval(() => {
      fetchPicks()
    }, 1000 * 60);

    return pollingId;
  };

  const fetchData = async () => {
    if (points == null) await fetchPoints()

    if (picks.length == 0) fetchPicks()
  }

  return (
    <Box>
      <AppBar position={"relative"} sx={{
        background: "transparent", // "rgba(255, 255, 255, 0.95)",
        opacity: "0.95",
        color: "text.primary",
        boxShadow: "none",
        borderBottom: "none",
        pt: 2,
        pb: 2
      }}>
        <Container maxWidth="md">
          <Stack direction="row" alignItems="center"  sx={{
            justifyContent: "space-between",
          }}>

            <IconButton sx={{position: "relative", left: -10}} component={RouterLink} to="/app">
              <img
                src="https://res.cloudinary.com/dxxog3y9j/image/upload/v1726686197/gravy_1_we9oi7.png"
                style={{
                  height: 40
                }}/>

            </IconButton>

            <Stack direction="row" spacing={1} alignItems="center">
              {
                points !== null &&
                  <Button
                    component={RouterLink}
                    to="/app/points"
                    variant="outlined"
                    startIcon={<Zap />}>
                    {
                      fShortenNumber(points)
                    } Pts
                  </Button>
              }

              <Button
                color="primary"
                startIcon={<Soup />}
                variant="contained"
                component={RouterLink} to="/app/feed">
                Picks ({picks.length})
              </Button>

              <IconButton component={RouterLink} to="/app/account">
                <CircleUser />
              </IconButton>
            </Stack>


          </Stack>

        </Container>
      </AppBar>
    </Box>
  );
};

const select = $$state => _.pick($$state, "user", "picks", "points");
export default connect(select, {
  ...pointsActions,
  ...picksActions
})(withRouter(TopNavBar));
